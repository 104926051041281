export default class Persona {
  static async list() {
    return fetch(`${process.env.REACT_APP_API_URL}/personas`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/personas/${id}`).then(res =>
      res.json()
    )
  }
}
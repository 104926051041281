import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import MarketSelect from '../markets/Select.js'
import { useQuery } from 'react-query';
import Industry from '../industries/Industry.js';

export default function Show() {
  const { id } = useParams();
  const { isLoading, error, data } = useQuery(['industry', id], () => Industry.show(id), {
    placeholderData: {
      name: '',
      market: {id: ''}
    }
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <h1 className='display-6'>Industry</h1>
        <Col>
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control disabled type="text" placeholder="Name" value={data.name} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Market" className="mb-3">
            <MarketSelect id={data.market.id} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/industries/${data.id}/edit`} className="btn btn-primary">
            Edit
          </Link>

          <Link to={"/industries"} className="btn">
            Back to list
          </Link>
        </Col>
      </Row>
    </Container>
  )
}
export default class Industry {
  static async list(page) {
    return fetch(`${process.env.REACT_APP_API_URL}/industries?page=${page}`).then(res =>
      res.json()
    )
  }
  
  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/industries/${id}`).then(res =>
      res.json()
    )
  }

  static async create(industry) {
    return fetch(`${process.env.REACT_APP_API_URL}/industries`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(industry)
      })
      .then(res => res.json())
  }

  static async edit(industry) {
    return fetch(`${process.env.REACT_APP_API_URL}/industries/${industry.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(industry)
      })
      .then(res => res.json())
  }

  static async delete(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/industries/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
  }
}
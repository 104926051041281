import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import MarketSelect from '../markets/Select.js'
import { useQuery } from 'react-query';
import Persona from '../personas/Persona.js';

export default function Show() {
  const { id } = useParams();
  const { isLoading, error, data } = useQuery(['persona', id], () => Persona.show(id), {
    placeholderData: {
      first_name: '',
      last_name: '',
      market: {id: ''},
      domain: '',
      voicemail_audio_url: ''
    }
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <Col>
          <h1 className='display-6'>Persona</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="First Name" className="mb-3">
            <Form.Control disabled type="text" value={data.first_name} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Last Name" className="mb-3">
            <Form.Control disabled type="text" value={data.last_name} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Market" className="mb-3">
            <MarketSelect id={data.market.id} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Domain" className="mb-3">
            <Form.Control disabled type="text" value={data.domain} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <audio controls>
            <source src={data.voicemail_audio_url} type="audio/mpeg" />
          </audio>
        </Col>
        <Col>
          <FloatingLabel label="Voicemail URL" className="mb-3">
            <Form.Control disabled type="text" value={data.voicemail_audio_url} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Created" className="mb-3">
            <Form.Control disabled type="text" value={data.created_at} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Updated" className="mb-3">
            <Form.Control disabled type="text" value={data.updated_at} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={"/personas"} className="btn">
            Back to list
          </Link>
        </Col>
      </Row>
    </Container>
  )
}
export default class PhoneNumber {
  static async list() {
    return fetch(`${process.env.REACT_APP_API_URL}/phone_numbers`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/phone_numbers/${id}`).then(res =>
      res.json()
    )
  }

  static async create(p) {
    return fetch(`${process.env.REACT_APP_API_URL}/phone_numbers`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(p)
      })
      .then(res => res.json())
  }

  static async edit(p) {
    return fetch(`${process.env.REACT_APP_API_URL}/phone_numbers/${p.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(p)
      })
      .then(res => res.json())
  }
}
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import User from '../users/User.js'

export default function List({ companyId }) {
  const { isLoading, error, data } = useQuery(['users'], () => User.list(), {
    placeholderData: []
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1 className='display-6'>Users</h1>
        </Col>
        <Col>
          <Link to={`/users/new`} className='btn btn-primary'>
            New User
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
            { data.map((user) => (
              <tr key={user.id}>
                <td>
                  <Link to={`/users/${user.id}`}>
                    {user.id}
                  </Link>
                </td>
                <td className='text-truncate'>{user.name}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import PersonaSelect from '../personas/Select.js'
import { useQuery } from 'react-query';
import Shopper from '../shoppers/Shopper.js';
import FormList from '../forms/List.js';

export default function Show() {
  const { id } = useParams();
  const { isLoading, error, data } = useQuery(['shopper', id], () => Shopper.show(id), {
    placeholderData: {
      name: '',
      company_id: '',
      persona: {
        id: ''
      }
    }
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <h1 className='display-6'>Shopper</h1>
        <Col>
          <FloatingLabel label="Phone" className="mb-3">
            <Form.Control disabled type="text" placeholder="Phone" value={data.phone} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Persona" className="mb-3">
            <PersonaSelect id={data.persona.id} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Phone" className="mb-3">
            <Form.Control disabled type="text" value={data.phone} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Created" className="mb-3">
            <Form.Control disabled type="text" placeholder="Created" value={data.created_at} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Updated" className="mb-3">
            <Form.Control disabled type="text" placeholder="Updated" value={data.updated_at} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/industries/${data.id}/edit`} className="btn btn-primary">
            Edit
          </Link>

          <Link to={`/companies/${data.company_id}`} className="btn">
            Back to company
          </Link>
        </Col>
      </Row>
      <Row className='pt-5'>
        <FormList shopperId={data.company_id} />
      </Row>
    </Container>
  )
}
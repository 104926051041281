import React from 'react';
import Nav from 'react-bootstrap/Nav';

export default function Navigation() {
  return (
    <Nav>
      <Nav.Item>
        <Nav.Link href="/companies">Companies</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/industries">Industries</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/personas">Personas</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/users">Users</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/statuses">Statuses</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/markets">Markets</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/phone_numbers">Phone Numbers</Nav.Link>
      </Nav.Item>
    </Nav>
  )
}
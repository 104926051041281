import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Shopper from '../shoppers/Shopper.js'

export default function List({ companyId }) {
  const { isLoading, error, data } = useQuery(['shoppers', companyId], () => Shopper.list(companyId), {
    placeholderData: []
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1 className='display-6'>Shoppers</h1>
        </Col>
        <Col>
          <Link to={`/shoppers/new`} className='btn btn-primary'>
            New Shopper
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Persona</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
            { data.map((shopper) => (
              <tr key={shopper.id}>
                <td>
                  <Link to={`/shoppers/${shopper.id}`}>
                    {shopper.id}
                  </Link>
                </td>
                <td className='text-truncate'>{shopper.persona.first_name}</td>
                <td className='text-truncate'>{shopper.phone}</td>
                <td className='text-truncate'>{shopper.email}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import IndustrySelect from '../industries/Select.js'
import { useQuery } from 'react-query';
import Company from '../companies/Company.js';
import ShopperList from '../shoppers/List.js'

export default function Show() {
  const { id } = useParams();
  const { isLoading, error, data } = useQuery(['company', id], () => Company.show(id), {
    placeholderData: {
      name: '',
      website: '',
      address: '',
      city: '',
      industry_id: '',
      state_abbreviation: '',
      zipcode: ''
    }
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <h1 className='display-6'>Company</h1>
        <Col>
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control disabled type="text" value={data.name} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Industry" className="mb-3">
            <IndustrySelect id={data.industry_id} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Area Code" className="mb-3">
            <Form.Control disabled type="text" value={data.area_code} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Website" className="mb-3">
            <Form.Control disabled type="text" value={data.website} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Address" className="mb-3">
            <Form.Control disabled type="text" value={data.address} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="City" className="mb-3">
            <Form.Control disabled type="text" value={data.city} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="State" className="mb-3">
            <Form.Control disabled type="text" value={data.state_abbreviation} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Zip" className="mb-3">
            <Form.Control disabled type="text" value={data.zipcode} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Latitude" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.latitude} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Longitude" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.longitude} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Created" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.created_at} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Updated" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.updated_at} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/companies/${data.id}/edit`} className="btn btn-primary">
            Edit
          </Link>

          <Link to={"/companies"} className="btn">
            Back to list
          </Link>
        </Col>
      </Row>
      <Row className='pt-5'>
        <ShopperList companyId={data.id} />
      </Row>
    </Container>
  )
}
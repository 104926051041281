import React from "react";
import Loader from "react-loader-spinner";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Clipboard } from "react-bootstrap-icons";
import copy from "copy-to-clipboard";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import parsePhoneNumber from "libphonenumber-js";

class FormSubmission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      data: null,
      isLoading: true,
      isError: false,
      userId: Cookies.get("user_id"),
      userOptions: [],
      recording: false,
      revealShopper: false,
      formSize: 0,
      formIndex: 0,
    };
    this.pageLoaded = false; // Non State variable

    this.getForm = this.getForm.bind(this);
    this.copyText = this.copyText.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.takeScreenshot = this.takeScreenshot.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.setUser = this.setUser.bind(this);
    this.buildUserOptions = this.buildUserOptions.bind(this);
  }

  getForm() {
    let query =
      "form_status_id[]=2&form_status_id[]=3&form_status_id[]=4&form_status_id[]=5&form_status_id[]=6" +
      `&user_id=${this.state.userId}`;
    fetch(`${process.env.REACT_APP_API_URL}/form_submissions?${query}`)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ data: responseJson });
        this.setState({ isLoading: false });
        this.setState({ formSize: responseJson.length });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.setState({ isError: true });
      });
  }

  formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // Monday, August 3rd at 2pm EDT
    const date = new Date(dateString);
    const hour = (date.getHours() + 24) % 12 || 12;
    const minutes = `${date.getMinutes()}`.padStart(2, "0");
    const amPm = date.getHours() >= 12 ? "pm" : "am";
    const timezone = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    const day = date.getDate();
    return `${
      months[date.getMonth()]
    } ${day}, ${date.getFullYear()} ${hour}:${minutes}${amPm} ${timezone}`;
  }

  copyText(text) {
    copy(text);
  }

  deleteScreenshot(screenshotId) {
    const options = {
      method: "DELETE",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/screenshots/${screenshotId}`,
      options
    )
      .then((response) => response.text())
      .then((data) => this.getForm(this.state.formId))
      .catch((error) => {});
  }

  takeScreenshot() {
    if (window.ipcRenderer && !this.state.recording) {
      this.setState({ recording: true });
      window.ipcRenderer.send(
        "screenshot",
        this.state.data[this.state.formIndex].id
      );
    }
  }

  stopScreenshots() {
    if (window.ipcRenderer && this.state.recording) {
      this.setState({ recording: false });
      window.ipcRenderer.send(
        "stopScreenshots",
        this.state.data[this.state.formIndex].id
      );
    }
  }

  hideShopperClass() {
    if (this.state.revealShopper) return "";
    return "d-none";
  }

  saveForm(form_status_id, success) {
    this.pageLoaded = false;
    this.stopScreenshots(); // Be sure to stop and send screenshots if still recording
    let body = {
      form_submission: {
        form_status_id: form_status_id,
      },
    };
    if (success) {
      const now = new Date();
      body["form_submission"]["submitted_at"] = now.toISOString();
    }
    this.setState({ isLoading: false });
    const options = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/form_submissions/${
        this.state.data[this.state.formIndex].id
      }`,
      options
    )
      .then((response) => response.json())
      .then((data) => window.location.reload())
      .catch((error) => {
        this.setState({ isLoading: false });
        this.setState({ isError: true });
      });
  }

  hasNext() {
    return this.state.formIndex + 1 < this.state.formSize;
  }

  hasPrevious() {
    return this.state.formIndex > 0;
  }

  nextCompany() {
    if (!this.hasNext()) {
      return;
    }

    return this.state.data[this.state.formIndex + 1].company?.name;
  }

  prevCompany() {
    if (!this.hasPrevious()) {
      return;
    }

    return this.state.data[this.state.formIndex - 1].company?.name;
  }

  setNextForm() {
    const idx = this.state.formIndex + 1;
    this.setState({ formIndex: idx }, () => {
      this.pageLoaded = false;
      this.sendLoadEvent();
    });
  }

  setPreviousForm() {
    const idx = this.state.formIndex - 1;
    this.setState({ formIndex: idx }, () => {
      this.pageLoaded = false;
      this.sendLoadEvent();
    });
  }

  buildScreenshots() {
    return (
      <div>
        {this.state.data[this.state.formIndex].screenshots.map(
          (screenshot, index) => {
            return (
              <Card key={screenshot.id}>
                <Card.Img
                  variant="bottom"
                  src={screenshot.image_url}
                  alt="Screenshot"
                  className="img-thumbnail"
                  style={{ width: "600px", height: "400px" }}
                />
                <Card.Body>
                  <Card.Text>{screenshot.name}</Card.Text>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      this.deleteScreenshot(screenshot.id);
                    }}
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            );
          }
        )}
      </div>
    );
  }

  componentDidMount() {
    if (this.state.userId) {
      this.getForm();
    } else {
      this.getUsers();
    }
    if (window.ipcRenderer) {
      let _this = this;
      window.ipcRenderer.on("screenshot-uploaded", function () {
        _this.getForm(_this.state.formId);
      });
    }
  }

  getUsers() {
    fetch(`${process.env.REACT_APP_API_URL}/users`)
      .then((response) => response.json())
      .then((data) => this.buildUserOptions(data))
      .catch((error) => {});
  }

  buildUserOptions(users) {
    let options = [];
    users.forEach((user) => {
      options.push(<option value={user.id}>{user.name}</option>);
    });
    this.setState({ userOptions: options });
  }

  setUser(e) {
    Cookies.set("user_id", e.target.value);
    this.setState({ userId: e.target.value }, function () {
      this.getForm();
    });
  }

  // If shopper is not revealed or if recording is ongoing disable certain elements
  isReadyToRecord() {
    // if not revealed then disabled, if revealed then if recording then disabled
    if (this.state.recording) return true;
    return false;
  }

  formatPhoneValue = (phone) => {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return phoneNumber.formatNational();
    }
    return phone;
  };

  sendLoadEvent() {
    if (window.ipcRenderer && !this.pageLoaded) {
      let form = this.state.data[this.state.formIndex];
      this.pageLoaded = true;
      window.ipcRenderer.send("website", {
        website: form.company.website,
        formSubmissionId: form.id,
      });
    }
  }

  render() {
    if (!this.state.userId) {
      return (
        <Container>
          <Row className="mt-5">
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Who are you?
                </Form.Label>
                <Col sm="8">
                  <Form.Control as="select" onChange={this.setUser}>
                    <option>---</option>
                    {this.state.userOptions}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      );
    }

    if (this.state.isLoading) {
      return (
        <Container>
          <Row>
            <Col className="text-center p-5">
              <p>Loading...</p>
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={160}
                width={160}
              />
            </Col>
          </Row>
        </Container>
      );
    }

    if (this.state.isError) {
      return <div>'Error!'</div>;
    }

    if (this.state.data.length === 0) {
      return (
        <Container>
          <Row className="pt-5">
            <Col>
              <h3>No Forms Found</h3>
              <p>There are no forms to process at this time</p>
            </Col>
          </Row>
        </Container>
      );
    }

    let form = this.state.data[this.state.formIndex];
    this.sendLoadEvent();

    return (
      <Container className="m-4">
        <Row>
          <Col>Forms remaining: {this.state.formSize}</Col>
          <Col xs="4">
            {this.hasNext() && (
              <a href="#next" onClick={() => this.setNextForm()}>
                Next: {this.nextCompany()}
              </a>
            )}
          </Col>
          <Col xs="4">
            {this.hasPrevious() && (
              <a href="#prev" onClick={() => this.setPreviousForm()}>
                Prev: {this.prevCompany()}
              </a>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Form Submission</h3>
            <dl className="row">
              <dt className="col-sm-3">Company</dt>
              <dd className="col-sm-9">{form.company.name}</dd>
              <dt className="col-sm-3">Website</dt>
              <dd className="col-sm-9" id="website">
                {form.company.website}
              </dd>
              <dt className="col-sm-3">Created At</dt>
              <dd className="col-sm-9">{this.formatDate(form.created_at)}</dd>
              <dt className="col-sm-3">Updated At</dt>
              <dd className="col-sm-9">{this.formatDate(form.updated_at)}</dd>
              <dt className="col-sm-3">Status</dt>
              <dd className="col-sm-9">{form.form_status.name}</dd>
              <dt className="col-sm-3">Description</dt>
              <dd className="col-sm-9">{form.form_status.description}</dd>
            </dl>
            <h3>Schedule</h3>
            <dl className="row">
              <dt className="col-sm-3">Begin</dt>
              <dd className="col-sm-9">
                {this.formatDate(form.schedule.starts_at)}
              </dd>
              <dt className="col-sm-3">End</dt>
              <dd className="col-sm-9">
                {this.formatDate(form.schedule.ends_at)}
              </dd>
            </dl>
            <hr />
            <Alert variant="danger">
              <Alert.Heading>Action</Alert.Heading>
              <p>
                Our system was unable to find the contact form on the website.
                Please look around for the contact form. Were you able to find
                it?
              </p>
              <hr />
              <Button onClick={() => this.setState({ revealShopper: true })}>
                Yes, I found it
              </Button>{" "}
              <Button variant="danger" onClick={() => this.saveForm(7, false)}>
                No, I was not able to find it
              </Button>
            </Alert>
            <div id="shopper" className={this.hideShopperClass()}>
              <Row>
                <Col>
                  <h3>Screen Grabs</h3>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.takeScreenshot();
                    }}
                    disabled={this.isReadyToRecord()}
                    id="take_screenshot"
                  >
                    Start Recording
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.stopScreenshots();
                    }}
                    id="stop_recording"
                  >
                    Stop Recording
                  </Button>
                </Col>
              </Row>
              <div id="screenshots">{this.buildScreenshots()}</div>
              <hr />
              <h3>Shopper</h3>
              <dl className="row">
                <dt className="col-sm-3">First Name</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.shopper.persona.first_name);
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.shopper.persona.first_name}
                </dd>
                <dt className="col-sm-3">Last Name</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.shopper.persona.last_name);
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.shopper.persona.last_name}
                </dd>
                <dt className="col-sm-3">Full Name</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(
                        form.shopper.persona.first_name +
                          " " +
                          form.shopper.persona.last_name
                      );
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.shopper.persona.first_name}{" "}
                  {form.shopper.persona.last_name}
                </dd>
                <dt className="col-sm-3">Email</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.shopper.email);
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.shopper.email}
                </dd>
                <dt className="col-sm-3">Phone</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.shopper.phone.replace("+1", ""));
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {this.formatPhoneValue(form.shopper.phone)}
                </dd>
              </dl>
              <h3>Message</h3>
              <dl className="row">
                <dt className="col-sm-3">Subject</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.industry_message.subject);
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.industry_message.subject}
                </dd>
                <dt className="col-sm-3">Message</dt>
                <dd className="col-sm-9">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.copyText(form.industry_message.body);
                    }}
                  >
                    <Clipboard />
                  </Button>{" "}
                  {form.industry_message.body}
                </dd>
              </dl>
              <hr />
              <Alert variant="danger">
                <Alert.Heading>Action</Alert.Heading>
                <p>
                  Were you able to submit the contact form with the above
                  shopper and message?
                </p>
                <hr />
                <Button onClick={() => this.saveForm(10, true)}>
                  Yes, I submitted the form
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() => this.saveForm(9, false)}
                >
                  No, I was unable to submit the form
                </Button>
              </Alert>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(FormSubmission);

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import PersonaSelect from '../personas/Select.js'
import Shopper from '../shoppers/Shopper.js';

export default function Create() {
  let history = useHistory();
  const mutation = useMutation(newShopper => Shopper.create(newShopper), {
    onSuccess: (data) => {
      history.push('/shoppers');
    }
  })

  const [name, setName] = useState('');
  const [personaId, setPersonaId] = useState('');

  function save() {
    mutation.mutate({
      name: name,
      persona_id: personaId
    })
  }

  return (
    <Container>
      {mutation.isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {mutation.error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {mutation.error.message}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <Col>
          <Form>
            <Row>
              <Col>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control type="text" placeholder="Name" onChange={e => setName(e.target.value)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Persona" className="mb-3">
                  <PersonaSelect setId={setPersonaId} />
                </FloatingLabel>
              </Col>
            </Row>

            <Button variant="primary" className="mr-3" onClick={save}>
              Save
            </Button>

            <Link to={"/personas"} className='btn'>
              Back to list
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
export default class Form {
  static async list(shopperId) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_submissions?company_id=${shopperId}`).then(res =>
      res.json()
    )
  }
  
  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_submissions/${id}`).then(res =>
      res.json()
    )
  }

  static async create(form) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_submissions`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form)
      })
      .then(res => res.json())
  }

  static async edit(form) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_submissions/${form.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form)
      })
      .then(res => res.json())
  }

  static async delete(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_submissions/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      })
  }
}
import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { useQuery } from 'react-query';
import Market from '../markets/Market.js'

export default function List({ companyId }) {
  const { isLoading, error, data } = useQuery(['markets'], () => Market.list(), {
    placeholderData: []
  })

  return (
    <Container>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1 className='display-6'>Markets</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
            { data.map((m) => (
              <tr key={m.id}>
                <td>{m.id}</td>
                <td className='text-truncate'>{m.name}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default class Status {
  static async list() {
    return fetch(`${process.env.REACT_APP_API_URL}/form_statuses`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/form_statuses/${id}`).then(res =>
      res.json()
    )
  }
}
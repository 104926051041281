export default class Industry {
  static async list(company_id) {
    return fetch(`${process.env.REACT_APP_API_URL}/shoppers?company_id=${company_id}`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/shoppers/${id}`).then(res =>
      res.json()
    )
  }
}
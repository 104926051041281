import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';
import MarketSelect from '../markets/Select.js'
import { useMutation, useQuery } from 'react-query';
import Industry from '../industries/Industry.js';

export default function Edit() {
  const { id } = useParams();
  let history = useHistory();
  const [name, setName] = useState();
  const [marketId, setMarketId] = useState();
  const mutation = useMutation(editIndustry => Industry.edit(editIndustry), {
    onSuccess: (data) => {
      history.push(`/industries/${data.id}`);
    }
  });
  const deleteMutation = useMutation(id => Industry.delete(id), {
    onSuccess: () => {
      history.push('/industries');
    }
  });
  const { isLoading, error, data } = useQuery(['industry', id], () => Industry.show(id), {
    placeholderData: {
      name: '',
      market: {id: ''}
    },
    onSuccess: (data) => {
      setName(data.name)
      setMarketId(data.market.id)
    }
  })

  function save() {
    mutation.mutate({
      id: data.id,
      name: name,
      market_id: marketId
    })
  }

  function destroy() {
    let confirmAction = window.confirm(`Are you sure you want to delete ${data.name}?`);
    if (confirmAction) {
      deleteMutation.mutate(data.id)
    }
  }

  return (
    <Container className='pt-3'>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <h1 className='display-6'>Industry</h1>
      <Row>
        <Col>
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control type="text" value={data.name} onChange={e => setName(e.target.value)} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Market" className="mb-3">
            <MarketSelect setId={setMarketId} id={marketId} />
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button variant="primary" className="mr-3" onClick={save}>
            Save
          </Button>
          <Link to={`/industries/${data.id}`} className="btn">
            Cancel
          </Link>
          <Link to={"/industries"} className="btn">
            Back to list
          </Link>
          <Button variant="danger" className="mr-3 float-right" onClick={destroy}>
            Delete
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
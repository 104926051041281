import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Company from '../companies/Company.js'

export default function List() {
  const [page, setPage] = useState(1);
  const { isLoading, error, data } = useQuery(['companies', page], () => Company.list(page), {
    placeholderData: []
  })

  return (
    <Container>
        {isLoading && (
          <Row className='pt-3'>
            <Col>
              <Alert variant='info'>
                Loading...
              </Alert>
            </Col>
          </Row>
          )}
        {error && (
          <Row className='pt-3'>
            <Col>
              <Alert variant='danger'>
                {error}
              </Alert>
            </Col>
          </Row>
        )}
      <Row className='p-3'>
        <Col>
          Companies
        </Col>
        <Col>
          <Pagination>
            <Pagination.Prev disabled={page === 1} onClick={() => setPage(page - 1)} />
            <Pagination.Next onClick={() => setPage(page + 1)} />
          </Pagination>
        </Col>
        <Col>
          <Form.Control type="text" placeholder="Page" onKeyDown={(e) => setPage(parseInt(e.target.value))} />
        </Col>
        <Col>
          <Link to={`/companies/new`} className='btn btn-primary'>
            New Company
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Website</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
              </tr>
            </thead>
            <tbody>
            { data.map((company) => (
              <tr key={company.id}>
                <td>
                  <Link to={`/companies/${company.id}`}>
                    {company.id}
                  </Link>
                </td>
                <td className='text-truncate'>{company.name}</td>
                <td className='text-truncate'>{company.website}</td>
                <td className='text-truncate'>{company.address}</td>
                <td className='text-truncate'>{company.city}</td>
                <td className='text-truncate'>{company.state_abbreviation}</td>
                <td className='text-truncate'>{company.zipcode}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
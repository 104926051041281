import React from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types'
import Market from '../markets/Market.js'
import Form from 'react-bootstrap/Form';

function Select ({ setId, id }) {
  const { isLoading, error, data } = useQuery('markets', () => Market.list(), {
    placeholderData: []
  })

  function sort(i) {
    return i.sort((a, b) => {
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
  }

  if (isLoading) {
    return 'Loading...'
  }

  if (error) {
    return 'An error has occurred: ' + error.message
  }

  return (
    <Form.Control
      disabled={typeof setId !== "function"}
      as="select"
      value={id}
      onChange={e => setId(parseInt(e.target.value))}
    >
      <option>-</option>
      {sort(data).map((market) => (
        <option key={market.id} value={market.id}>{market.name}</option>
      ))}
    </Form.Control>
  )
}

Select.propTypes = {
  setIndustryId: PropTypes.bool
}

export default Select
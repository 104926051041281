import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';
import IndustrySelect from '../industries/Select.js'
import { useMutation, useQuery } from 'react-query';
import Company from '../companies/Company.js';

export default function Edit() {
  const { id } = useParams();
  let history = useHistory();
  const mutation = useMutation(editCompany => Company.edit(editCompany));
  const { isLoading, error, data, isSuccess } = useQuery(['company', id], () => Company.show(id), {
    placeholderData: {
      name: '',
      website: '',
      address: '',
      city: '',
      industry_id: '',
      state_abbreviation: '',
      zipcode: ''
    }
  })

  const [name, setName] = useState(data.name);
  const [website, setWebsite] = useState(data.website);
  const [address, setAddress] = useState(data.address);
  const [city, setCity] = useState(data.city);
  const [state, setState] = useState(data.state);
  const [zipcode, setZipcode] = useState(data.zipcode);
  const [industryId, setIndustryId] = useState(data.industry_id);

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(`/companies/${data.id}`);
    }
  });

  useEffect(() => {
    if (isSuccess) {
      setName(data.name)
      setWebsite(data.website)
      setAddress(data.address)
      setCity(data.city)
      setState(data.state_abbreviation)
      setIndustryId(data.industry_id)
      setZipcode(data.zipcode)
    }
  }, [isSuccess, data]);

  function saveCompany() {
    mutation.mutate({
      id: data.id,
      name: name,
      website: website,
      address: address,
      city: city,
      state_abbreviation: state,
      zipcode: zipcode,
      industry_id: industryId
    })
  }

  return (
    <Container className='pt-3'>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <h1 className='display-6'>Company</h1>
      <Row>
        <Col>
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Industry" className="mb-3">
            <IndustrySelect setId={setIndustryId} id={industryId} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Website" className="mb-3">
            <Form.Control type="text" placeholder="Website" value={website} onChange={e => setWebsite(e.target.value)} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Address" className="mb-3">
            <Form.Control type="text" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="City" className="mb-3">
            <Form.Control type="text" placeholder="City" value={city} onChange={e => setCity(e.target.value)} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="State" className="mb-3">
            <Form.Control type="text" placeholder="State" value={state} onChange={e => setState(e.target.value)} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Zip" className="mb-3">
            <Form.Control type="text" placeholder="Zip" value={zipcode} onChange={e => setZipcode(e.target.value)} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Latitude" className="mb-3">
            <Form.Control disabled type="text" placeholder="Latitude" defaultValue={data.latitude} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Longitude" className="mb-3">
            <Form.Control disabled type="text" placeholder="Longitude" defaultValue={data.longitude} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel label="Created" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.created_at} />
          </FloatingLabel>
        </Col>
        <Col>
          <FloatingLabel label="Updated" className="mb-3">
            <Form.Control disabled type="text" defaultValue={data.updated_at} />
          </FloatingLabel>
        </Col>
      </Row>

      <Button variant="primary" className="mr-3" onClick={saveCompany}>
        Save
      </Button>

      <Link to={`/companies/${data.id}`} className="btn">
        Cancel
      </Link>

      <Link to={"/companies"} className="btn">
        Back to list
      </Link>
    </Container>
  );
}
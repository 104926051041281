import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import Industry from '../industries/Industry.js'

export default function List() {
  const [page, setPage] = useState(1);
  const { isLoading, error, data } = useQuery(['industries', page], () => Industry.list(page), {
    placeholderData: []
  })

  return (
    <Container className='pt-3'>
      {isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1 className='display-6'>Industries</h1>
        </Col>
        <Col>
          <Pagination>
            <Pagination.Prev disabled={page === 1} onClick={() => setPage(page - 1)} />
            <Pagination.Next onClick={() => setPage(page + 1)} />
          </Pagination>
        </Col>
        <Col>
          <Link to={`/industries/new`} className='btn btn-primary'>
            New Industry
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
            { data.map((industry) => (
              <tr key={industry.id}>
                <td>
                  <Link to={`/industries/${industry.id}`}>
                    {industry.id}
                  </Link>
                </td>
                <td className='text-truncate'>{industry.name}</td>
                <td className='text-truncate'>{industry.market.name}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default class Industry {
  static async list() {
    return fetch(`${process.env.REACT_APP_API_URL}/users`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/users/${id}`).then(res =>
      res.json()
    )
  }
}
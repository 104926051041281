import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import FormSubmission from "./FormSubmission";
import CompanyList from "./companies/List";
import CompanyShow from "./companies/Show";
import CompanyEdit from "./companies/Edit";
import CompanyCreate from "./companies/Create";
import PersonaList from "./personas/List";
import PersonaShow from "./personas/Show";
import IndustryList from "./industries/List";
import IndustryCreate from "./industries/Create";
import IndustryEdit from "./industries/Edit";
import IndustryShow from "./industries/Show";
import ShopperShow from "./shoppers/Show";
import ShopperCreate from "./shoppers/Create";
import UserList from "./users/List";
import StatusList from "./statuses/List";
import UserShow from "./users/Show";
import MarketList from "./markets/List";
import PhoneNumberCreate from "./phone_numbers/Create";
import PhoneNumberList from "./phone_numbers/List";
import PhoneNumberShow from "./phone_numbers/Show";
import Navigation from './Navigation.js';
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000,
      cacheTime: 600000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={true} />
    <Navigation />
      <Router>
        <Switch>
          <Route exact path="/companies/new" children={<CompanyCreate />} />
          <Route path="/companies/:id/edit" children={<CompanyEdit />} />
          <Route path="/companies/:id" children={<CompanyShow />} />
          <Route exact path="/companies" children={<CompanyList />} />

          <Route exact path="/personas" children={<PersonaList />} />
          <Route path="/personas/:id" children={<PersonaShow />} />

          <Route exact path="/industries/new" children={<IndustryCreate />} />
          <Route path="/industries/:id/edit" children={<IndustryEdit />} />
          <Route path="/industries/:id" children={<IndustryShow />} />
          <Route exact path="/industries" children={<IndustryList />} />

          <Route exact path="/shoppers/new" children={<ShopperCreate />} />
          <Route path="/shoppers/:id" children={<ShopperShow />} />

          <Route exact path="/users" children={<UserList />} />
          <Route path="/users/:id" children={<UserShow />} />

          <Route exact path="/statuses" children={<StatusList />} />

          <Route exact path="/markets" children={<MarketList />} />

          <Route exact path="/phone_numbers" children={<PhoneNumberList />} />
          <Route exact path="/phone_numbers/new" children={<PhoneNumberCreate />} />
          <Route path="/phone_numbers/:id" children={<PhoneNumberShow />} />

          <Route path="*" children={<FormSubmission />} />
        </Switch>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default class Company {
  static async list(page) {
    return fetch(`${process.env.REACT_APP_API_URL}/companies?page=${page}`).then(res =>
      res.json()
    )
  }

  static async show(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/companies/${id}`).then(res =>
      res.json()
    )
  }

  static async create(company) {
    return fetch(`${process.env.REACT_APP_API_URL}/companies`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(company)
      })
      .then(res => res.json())
  }

  static async edit(company) {
    return fetch(`${process.env.REACT_APP_API_URL}/companies/${company.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(company)
      })
      .then(res => res.json())
  }
}
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import IndustrySelect from '../industries/Select.js'
import Company from '../companies/Company.js';

export default function Create() {
  let history = useHistory();
  const mutation = useMutation(newCompany => Company.create(newCompany), {
    onSuccess: (data) => {
      history.push(`/companies/${data.id}`);
    }
  })

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [industryId, setIndustryId] = useState('');

  function saveCompany() {
    mutation.mutate({
      name: name,
      website: website,
      address: address,
      city: city,
      state_abbreviation: state,
      zipcode: zipcode,
      area_code: areaCode,
      industry_id: industryId
    })
  }

  return (
    <Container>
      {mutation.isLoading && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='info'>
              Loading...
            </Alert>
          </Col>
        </Row>
        )}
      {mutation.error && (
        <Row className='pt-3'>
          <Col>
            <Alert variant='danger'>
              {mutation.error.message}
            </Alert>
          </Col>
        </Row>
      )}
      <Row className='pt-3'>
        <Col>
          <Form>
            <Row>
              <Col>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control type="text" onChange={e => setName(e.target.value)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Industry" className="mb-3">
                  <IndustrySelect setId={setIndustryId} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="Area Code" className="mb-3">
                  <Form.Control type="text" onChange={e => setAreaCode(e.target.value)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Website" className="mb-3">
                  <Form.Control type="text" onChange={e => setWebsite(e.target.value)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="Address" className="mb-3">
                  <Form.Control type="text" onChange={e => setAddress(e.target.value)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="City" className="mb-3">
                  <Form.Control type="text" onChange={e => setCity(e.target.value)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="State" className="mb-3">
                  <Form.Control type="text" onChange={e => setState(e.target.value)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Zip" className="mb-3">
                  <Form.Control type="text" onChange={e => setZipcode(e.target.value)} />
                </FloatingLabel>
              </Col>
            </Row>

            <Button variant="primary" className="mr-3" onClick={saveCompany}>
              Save
            </Button>

            <Link to={"/companies"}>
              Back to list
            </Link>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}